@tailwind base;
@tailwind components;
@tailwind utilities;

.jsoneditor {
    border: none !important;
}

div[role="row"]:hover {
    background-color: rgb(230, 244, 244);
    border-bottom-color: #FFFFFF;
    outline: 1px solid #FFFFFF;
}

.status {
    display: inline-block;
    border-radius: 5px;
    padding: 5px;
    color: white;
    background-color: #4CAF50;
}

.status.Approved {
    background-color: #4CAF50;
}

.status.Reverted {
    background-color: #f44336;
}

.atable-parent div:first-child {
    overflow: auto !important;
}

.jsoneditor-menu {
    background-color: #00a38a !important;
}

.host-req {
    z-index: 2147483647 !important;
}

.lnOUep {
    white-space: unset !important;
}

.landpage-container {
    background-color: rgba(0, 201, 168, 0.8);
    border-radius: 20px;
    padding: 5px;
    width: 30%;
    height: 40%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.footer {
    background-color: #00ac8e;
}